import React, { useContext, useState } from 'react';
import {TodoContext} from '../Contexts/TodoContext'

const NewTodoForm = () => {
    const {dispatch} = useContext(TodoContext);
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch({type: 'ADD_TODO', todo: {
            id, title
        }})
        setId('');
        setTitle('');
    }

    return (
        <form onSubmit={handleSubmit}>
            <input type="text" placeholder="id" value={id}
              onChange={(e) => setId(e.target.value)} required />
            <input type="text" placeholder="title" value={title}
              onChange={(e) => setTitle(e.target.value)} required />
            <input type="submit" value="add todo" />
        </form>
    )
}

export default NewTodoForm;